.flight-list {

    // border: 1px solid $gray-300;
    font-size: 0.85em;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);
    -moz-box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);

    &__logo {
        width: 35px;
        height: auto;
        max-width: 35px;
        // max-height: 35px;
    }

    &__airline-time,
    &__price,
    &__airline-name {
        color: $black;
        font-size: 1.25em;
    }
    &__airline-date {
        font-size: 1em;
        color: #eb0c0c;
    }

    &__airline-id {
        font-size: 1em;
        color: #0cbaeb;
    }

    &__currency {
        font-size: 0.75em;
    }

    &__stops-wrap {
        width: 100%;
    }

    &__stops-indicator {
        border-bottom: 2px solid;
        // position: relative;
        width: 100%;
    }

    &__stops-indicator--green {
        border-color: $green;
    }

    &__stops-indicator--yellow {
        border-color: $yellow;
    }

    &__stops-indicator--dots-wrap {
        position: relative;
        top: -5px
    }

    &__stops-indicator--dots {
        width: 8px;
        height: 8px;
        border: 2px solid #e7e7e7;
        display: inline-block;
        background-color: #959595;
        // position: absolute;
        z-index: 0;
        margin: 0 2px;
        border-radius: 20px;
        // top: -3px;
    }
    
    &__stops-count {
        font-size: 0.9rem;
    }

    &__stops-count--multistop {
        margin-top: -5px;
    }
}

.individualSection{
    // border-top: 2px solid #3fc6ee;
    // border-bottom: 2px solid #3fc6ee;
}

.trip-details-airline-logo {
    width: 18px;
}

.fare-total-label {
    color: $black;
    font-size: 1.15em;  
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.trip-selected {
    background: lighten($brand-color-variant-2, 30%);
    border: 1px solid lighten($brand-color-variant-2, 30%);
    box-shadow: 0 0 3px 1px #f9c7ce;
    -webkit-box-shadow: 0 0 3px 1px #f9c7ce;
    -moz-box-shadow: 0 0 3px 1px #f9c7ce;

    .trip-summary {
        background: $white;
        border-radius: 0.25rem;
        padding: 0.5rem 0.5rem 0 0.5rem;
    }
}
.activeSectionContainer{
    box-shadow: 0px 0px 5px 2px #f9c7ce;
    -webkit-box-shadow: 0px 0px 5px 2px #f9c7ce;
    -moz-box-shadow: 0px 0px 5px 2px #f9c7ce;
}

.filter-sort-menu {
    background-color: rgba(62, 65, 82, 0.8);
}
.sort-item {
    background-color: rgb(29, 30, 41);
    border-radius: 15px;
    color: #fff;
    margin-right: 5px;

    &.sort-selected {
        background: #fff;
        color: #1176bd;
        border: 1px solid #1176bd;
    }
}

.filter-button {
    
}