body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(281deg, rgb(0, 82, 186), rgba(0, 138, 222, 0.84)); */
  /* background-color: #1aabe0 !important; */
  /* background: rgb(17,118,189); */
  /* background: linear-gradient(281deg, rgba(17,118,189,0.7) 0%, rgba(26,171,224,0.2) 100%); */
  background: linear-gradient(180deg, rgba(26,171,224,0.2) 0%, rgba(17,118,189,0.7) 100%);

  /* pull to refresh */
  overscroll-behavior-y: contain;

  /* Client suggested */
  /* background: linear-gradient(281deg, rgb(190 243 168), rgba(0, 138, 222, 0.84)); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.transparent-background {
  background: transparent !important;
}