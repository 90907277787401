.field-label {
    color: $gray-500;
}

.form-group{
    .form-control{
        padding-right: 48px;
    }
    .form-label-icon{
        left: auto;
        right: 7px;
    }
    .input-group-text {
        background-color: transparent;

        svg {
            height: 21px;
            width: 21px;
        }
    }
}

.location-search-modal {
    margin: 0;
    
    .modal-content {
        width: 100vw;
    }

    .modal-body {
        min-width: 100vw;
        min-height: 99.7vh;
    }

    .searchform__field--content-splitvalue {
        display: inline;
        margin-right: 5px;
    }

    .searchform__field {
        min-height: 50px;
    }
}

.modify-search-modal {
    margin: 0;
    
    .modal-content {
        width: 98vw;
    }

    .modal-body {
        min-width: 95vw;
        min-height: 70vh;
    }

    .searchform__field--content-splitvalue {
        display: inline;
        margin-right: 5px;
    }

    .searchform__field {
        min-height: 50px;
    }
}


.searchform__field {
    line-height: 14px;
    font-size: 11px;
    display: flex;
    flex-direction: row;
    background-color: #f6f6f7;
    border: 1px solid #eae1e1;
    margin-bottom: 15px;
    min-height: 68px;
    border-radius: 10px;

    &--icon {
        align-self: center;
        margin: 0 10px 0 5px;

        .icon-dual {
            width: 20px;
        }
    }

    &--content {
        width: 100%;
        align-self: center;

        &-label,
        &-splitvalue {
            margin: 0;
        }

        &-label {
            font-size: 11px;
            font-weight: 700;
            line-height: 14px;
        }
        &-splitvalue {
            .bold {
                color: $black;
                font-size: 14px;
                font-weight: 900;
                margin-right: 5px;
            }
            .small {
                font-size: 12px;
            }
        }
    }

    &.rotate .searchform__field--icon {
        transform: rotate(90deg);
    }

    &--search-input {
        border-width: 0;
        background-color: transparent;
        width: 100%;
    }
}

.searchlocation__list {
    max-height: 66vh;
    overflow-y: auto;
}

.searchlocation__listitem {
    display: flex;
    border: 0;
    border-bottom: 1px solid #ddd;
    padding-left: 0;
    // padding-right: 0;

    &--flex {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &--name p {
        margin: 0;
    }

    &--title {
        font-size: 1rem;
        line-height: 1;
    }

    &--subtitle {
        font-size: 0.8rem;
    }

    &--shortname {
        align-self: center;
        margin-left: 5px;
    }
}

    .hiddenCalendar {
        left: 7%;
        top: 0;
        width: 86%;
        height: 83%;
        z-index: 100;
        padding: 0 !important;
        opacity: 0;
    }

    .positionRelative input::-webkit-datetime-edit  {
        display: none;
    }

    .positionRelative input[type="date" i]::-webkit-calendar-picker-indicator {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

.search-tabs li a.nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.search-tabs .nav-item {
    width: 33%;
    text-align: center;
}

//Counter
.counter-button-group {
    border: 1px solid $gray-300;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    // padding: 0.2em;

    &-value {
        margin: 0 1em;
    }

    &-icon {
        font-size: 1em;
    }
}

.nav-tabs {
    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 6px 0 #00000033;
    border-bottom: 2px solid transparent !important;
    padding: 4px !important;
}

.nav-tabs li:has(a.active) { 
    background: red;
}

.nav-tabs li {
    border-color: transparent;
    a.active {
        border-bottom: 2px solid white !important;
        background-image: linear-gradient(281deg, rgb(0, 82, 186), rgba(0, 138, 222, 0.84)) !important;
        color: white !important;
        border-radius: 4px !important;
    }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: transparent !important; 
}

.search-tabs li a.nav-link {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.btn-custom {
    background-image: linear-gradient(281deg, rgb(0, 82, 186), rgba(0, 138, 222, 0.84));
}

.footer-menu {
    // background-color: #ddd;
    border-radius: 10px 10px 0 0;
}

.loader-wrap {
    border-radius: 50%;
    overflow: hidden;
}
.wrap-multi-text {
    width: 39px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
