.App {
  /* text-align: center; */
  background-color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.bold {
  font-weight: bold;
}

.positionRelative {
  position: relative;
}

.positionAbsolute {
  position: absolute;
}

.sticky-header-results {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.search-result-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
  background-color: #fafafa;
  border: 1px solid #e9e6e6;
}
.searchresult-insidesummary{
  width: 80%;
  display: flex;
}
.make_flex {
  display: flex!important;
}
.column {
  flex-direction: column;
}
.searchresult-insidesummary .ellipsis {
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 20px);
}
.font14 {
  font-size: 16px;
  line-height: 18px!important;
}
.latoBlack {
  font-weight: 900;
}
.latoBold {
  font-weight: 700;
}
.append_bottom3 {
  margin-bottom: 3px;
}
.font11 {
  font-size: 13px;
}
.font12 {
  font-size: 12px;
}
.lightGreyText {
  color: #9b9b9b;
}
.fix_header_mar_top_extra {
  margin-top: 75px;
}

.fix_header_mar_top_extra-pax-details {
  margin-top: 85px;
}

.main_input{
  border-radius: 4px;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(231, 231, 231);
}

.fieldsMultiCity--addCity{
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px dashed rgb(0, 140, 255);
  margin-bottom: 15px;
}
#topping {
  z-index: 0;
}

.freqFlyer {
  z-index : 0 !important;
}
.seat-content {
  overflow: auto !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}